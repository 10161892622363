import * as React from "react"

export class App extends React.Component {
    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <h1>
                    <span>— Site under construction! 🎉🎉🎉</span>
                </h1>
                <p>
                    😎
                </p>
                <ul>
                </ul>
            </React.Fragment>
        )
    }
}
