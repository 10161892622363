import * as React from "react"
import type { HeadFC } from "gatsby"
import {App} from '../App'


const IndexPage = () => {
  return (
    <main>
      <h1>AppRoot Below</h1>
      <App></App>
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>calebsimmons.online</title>
